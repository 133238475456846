import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import DataTable from "../../shared/DataGrid";
import { AgentTab } from "../../utills/formFields";
import SearchIcon from "../../assets/input-search-icon.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { AgentColumn } from "../../utills/tableFields";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgentList, updateAgentStatus } from "../../services/agent";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Crossimg from "../../assets/crossimg.svg";
import Checkicon from "../../assets/checkicon.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Crossicon from "../../assets/crossicon.svg";
import Deleteicon from "../../assets/deleteicon.svg";
import Checkimg from "../../assets/checkimg.svg";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import moment from "moment";

export default function AllAgent() {

  const navigate = useNavigate();

  const [actionTab, setActiveTab] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const dispatch = useDispatch();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false); // New state for Activate dialog
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState({
    action: "",
    id: "",
  });
  const open = Boolean(anchorEl);
  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [comment, setComment] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action, id) => {
    setConfirmAction({ action, id });
    if (action === "active") {
      setActivateDialogOpen(true);
    } else if (action === "delete") {
      handleDeleteDialogOpen();
    } else {
      setConfirmDialogOpen(true);
    }
    handleClose();
  };

  const handleConfirmAction = () => {
    // Validate comment for "deactivated" status
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogImage(Crossicon);
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Agent successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Agent successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "delete":
        message = `Agent successfully deleted.`;
        imageSrc = Deleteicon;
        break;
      default:
        break;
    }

    dispatch(
      updateAgentStatus({
        agent_id: confirmAction.id,
        status: confirmAction.action,
        reason: comment.trim(), // Ensure reason is non-empty and trimmed
      })
    );

    setTimeout(() => {
      dispatch(getAllAgentList());
    }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment(""); 
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false); 
      window.location.reload();
    }, 2000);
  };

  const state = useSelector((state) => state);
  console.log("state", state);

  const agentInfo = useSelector((state) => state.agentInfo.details.data);
  console.log("abd", agentInfo);

  useEffect(() => {
    dispatch(getAllAgentList());
  }, []);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(data);
  };

  const handleDialogClose = () => setSuccessDialogOpen(false);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  // const handleDeleteConfirm = () => {
  //   // Simulate delete logic
  //   const message = `Agent successfully deleted.`;
  //   const imageSrc = Deleteicon;
  //   setDialogMessage(message);
  //   setDialogImage(imageSrc);
  //   setDeleteDialogOpen(false);
  //   setSuccessDialogOpen(true);
  // };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const AgentsColumn = [
    ...AgentColumn,
    {
      field: "location",
      headerName: "Last Update Date & Time",
      flex: 2,
      id: 6,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h: mm A" );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      id: 5,
      renderCell: (params) => {
        const statusStyles = {
          active: { color: "#29B473" },
          inactive: { color: "#D22735" },
        };

        const status = params.row.status;
        const { color } = statusStyles[status] || { color: "#6c757d" };

        return (
          <div
            className="flex items-center"
            style={{
              color: color,
              borderRadius: "10px",
              textAlign: "center",
              minWidth: "80px",
            }}
          >
            {status === "active" ? (
              <CheckIcon style={{ color, width:20, height:20 }} />
            ) : (
              <BlockIcon style={{ color, width:18, height:18 }} />
            )}
            <span style={{ marginLeft: "8px" }}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        const status = params.row.status;

        return (
          <>
            <Menu as="div" className="inline-block text-left mt-2">
              <div>
                <MenuButton
                  onClick={(e) => handleClick(e, params.row)}
                  className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900"
                >
                  <img src={MoreIcon} alt="MoreIcon" />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() =>
                      navigate(`/view-agent/${selectedRow?.agent_id}`)
                    }
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(`/addnewagent?id=${selectedRow.id}`)
                    }
                  >
                    <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-[#EEF4FF] hover:text-gray-900">
                      Edit
                    </p>
                  </MenuItem>
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => handleAction("inactive", params.id)}
                    >
                      <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        Deactivate
                      </p>
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem
                        onClick={() => handleAction("active", params.id)}
                      >
                        <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                          Activate
                        </p>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleAction("delete", params.id)}
                        className="text-red-600 hover:bg-[#FEE2E2] hover:text-red-800"
                      >
                        <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                          Delete
                        </p>
                      </MenuItem>
                    </>
                  )}
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];

  const activeCount =
    agentInfo?.filter((agent) => agent.status === "active").length || 0;
  const inactiveCount =
    agentInfo?.filter((agent) => agent.status === "inactive").length || 0;

  const filteredRows = agentInfo?.filter((agent) => {
    if (actionTab === "active") return agent.status === "active";
    if (actionTab === "inactive") return agent.status === "inactive";
    return true;
  });

  const rows =
    filteredRows?.map((agent) => ({
      id: agent.id,
      agent_id: agent.id,
      full_name: `${agent.first_name} ${agent.last_name}`,
      email: agent.email,
      phone_number: agent.contact_number,
      location: agent.updated_at,
      status: agent.status,
    })) || [];

  return (
    <>
      <div className="px-8 mt-10">
        <BreadCumbs
          firstCumbName="Agent"
          secoundCumbName="Agent Status"
          pathToRedirect="addnewagent"
        />

        <div className="flex justify-between p-6">
          <div className="text-[#18283D] text-[20px] font-sans">Agent</div>
        </div>

        <div
          className="px-3 py-5 mb-5"
          style={{
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
          }}
        >
          <div className="details-info">
            <div
              className="flex justify-between px-5 mb-8 items-center"
              style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
            >
              <ul className="tab-list flex">
                <li
                  className={`relative ${
                    actionTab === "all" ? "active" : "inactive"
                  }`}
                  onClick={() => setActiveTab("all")}
                >
                  <div className="flex justify-center pb-1">
                    <div className="total-proram-count relative">
                      {agentInfo?.length || 0}
                    </div>
                  </div>
                  All
                </li>
                <li
                  className={`relative ${
                    actionTab === "active" ? "active" : "inactive"
                  }`}
                  onClick={() => setActiveTab("active")}
                >
                  <div className="flex justify-center pb-1">
                    <div className="total-proram-count relative">
                      {activeCount}
                    </div>
                  </div>
                  Active
                </li>
                <li
                  className={`relative ${
                    actionTab === "inactive" ? "active" : "inactive"
                  }`}
                  onClick={() => setActiveTab("inactive")}
                >
                  <div className="flex justify-center pb-1">
                    <div className="total-proram-count relative">
                      {inactiveCount}
                    </div>
                  </div>
                  Inactive
                </li>
              </ul>
              <div className="relative">
                <input
                  type="text"
                  id="search-navbar"
                  className="block w-full p-2 text-sm text-gray-900 border-none"
                  placeholder="Search here..."
                  style={{
                    border: "1px solid rgba(34, 96, 217, 1)",
                    height: "50px",
                    width: "390px",
                  }}
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                  <img src={SearchIcon} alt="SearchIcon" />
                </div>
                {/* <div className="text-[13px]">{`${discussion.name}`}</div> */}
              </div>
            </div>
          </div>
          <DataTable rows={rows} columns={AgentsColumn} hideCheckbox />
        </div>
      </div>

      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Checkimg} alt="Check" className="mx-2" />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2" />
          </div>

          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="text-red-600 ml-1">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl overflow-hidden"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className=" mx-2" />

            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className=" mx-2" />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>

            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Deleteicon} alt="" />
            </Typography>
          </div>

          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure want to delete this Agent?
            </Typography>
          </DialogContent>

          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
