import React from "react";
import { useSelector } from "react-redux";
import Consumer from "./Consumer";
import CommonDashboard from "./CommonDashboard";
import AdminDashBoard from "../Admin-DashBoard";

export default function Dashboard() {
  const { userType } = useSelector((state) => state.userInfo);

  return (
    <>
      {userType === "consumer" && <Consumer />}
      {(userType === "other" || userType === "agent") && <CommonDashboard />}
      {userType === "admin" && <AdminDashBoard />}
    </>
  );
}
