import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "./api";

// Create Request
export const createNewRequest = createAsyncThunk(
  "createNewRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const createRequest = await api.post(`/agent/`, requestData);
      if (createRequest.status === 200 && createRequest.data) {
        return createRequest.data;
      }
      throw new Error("Failed to create Request");
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const getAllCurrentRequestList = createAsyncThunk(
  "getAllCurrentRequestList",
  async () => {
    const getAllCurrentRequestInfo = await api.get('/agent/');
    if (getAllCurrentRequestInfo.status === 200 && getAllCurrentRequestInfo.data) {
      return getAllCurrentRequestInfo.data
    }
    return getAllCurrentRequestInfo
  }
);