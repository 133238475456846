import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const getUserType = createAsyncThunk (
    "getUserType",
    async () => {
        try{
            const response = await api.get("/user/user-details/");
            if (response.status === 200 && response.data.status === "success"){
                const result = response.data.result;
              
                console.log('userresult',result)
                return result;
            } else {
                throw new Error("Failed to fetch request count");
              }
            }
            catch (error) {
                console.error("Error fetching request count:", error);
                throw error; 
              }
            
        }
)