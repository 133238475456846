import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { LoginSocialGoogle } from "reactjs-social-login";
import { useForm } from "react-hook-form";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MuiModal from "./shared/Modal";
import MyProfile from "./components/MyProfile";
import { loginSocialMedia, updateUserInfo } from "./services/user";
import { userApiResponse } from "./constants/apiResponse";
import { Button } from "./shared/Button";
import GoogleIcon from "./assets/google-icon.svg";
import FacebookIcon from "./assets/facebook-icon.svg";
import InstagramIcon from "./assets/instagram-icon.svg";
import PlusIcon from "./assets/plus-white-circle.svg";
import EmailIcon from "./assets/email-icon.svg";
import NotificationIcon from "./assets/notification-bell-icon.svg";
import UserImage from "./assets/images/user.png";
import HelpIcon from "./assets/help-icon.svg";
import ProfileIcon from "./assets/profile-icon.svg";
import LogoutIcon from "./assets/logout-icon.svg";
import LogoutColorIcon from "./assets/logout-popup-icon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import userGetIsUserAdmin from "./hooks/userGetIsUserAdmin";
import { getUserType } from "./services/userType";

export default function Navbar() {
  const navigate = useNavigate();

  const { data, status, userType } = useSelector((state) => state.userInfo);

  console.log("Data:", data);
  console.log("Status:", status);
  console.log("User Type:", userType);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  // const isUserAdmin = userGetIsUserAdmin();

  const [loginModal, setLoginModal] = useState();
  const [isLogout, setIsLogout] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userAction, setUserAction] = useState({ profileModal: false });
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(null);
  const [agentMenu, setAgentMenu] = useState(null);
  const [partnerMenu, setPartnerMenu] = useState(null);
  const [categoryMenu, setCategoryMenu] = useState(null);
  const [requestMenu, setRequestMenu] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState(false);

  const handleCloseLoginModal = () => {
    reset();
    setLoginModal(false);
  };
  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);
  const onLoginSuccess = (data) => {
    console.log("Google login", data);
    if (
      data &&
      Object.keys(data).length &&
      data.hasOwnProperty("name") &&
      data.hasOwnProperty("email")
    ) {
      let l = { email: data.email };
      console.log("Login", l);
      dispatch(loginSocialMedia(l));
    }
  };
  const onLogoutFailure = useCallback(() => {
    alert("logout fail");
  }, []);
  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);
  const handleCloseUserProfile = () => {
    setUserAction({ ...userAction, profileModal: false });
  };

  const handleAgentMenu = (event) => {
    setAgentMenu(agentMenu ? null : event.currentTarget);
  };

  const handleCloseAgentMenu = () => {
    setAgentMenu(null);
  };

  const handlePartnerMenu = (event) => {
    setPartnerMenu(partnerMenu ? null : event.currentTarget);
  };

  const handleClosePartnerMenu = () => {
    setPartnerMenu(null);
  };

  const handleClickMenu = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleRequestMenu = (event) => {
    setRequestMenu(requestMenu ? null : event.currentTarget);
  };

  const handleCloseRequestMenu = () => {
    setRequestMenu(null);
  };

  const handleCategoryMenu = (event) => {
    setCategoryMenu(categoryMenu ? null : event.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setCategoryMenu(null);
  };

  const handleCloseMenu = () => {
    setMenuOpen(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userType");
    dispatch({ type: "logout" });
    navigate("/");
    setIsLogout(false);
    window.location.reload();
  };
  const onSubmit = (data) => {
    console.log("Submit");
  };
  useEffect(() => {
    if (status === userApiResponse.loggin) {
      handleCloseLoginModal();
      dispatch(updateUserInfo({ status: "" }));
    }
  }, [status]);

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  return (
    <>
      <header
        className="py-3 bg-white"
        style={{ borderBottom: "1px solid #353F4F" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLogout}
        >
          <div className="popup-content w-2/6 bg-white flex flex-col gap-2 h-[330px] justify-center items-center">
            <img src={LogoutColorIcon} alt="LogoutColorIcon" />
            <span
              style={{ color: "#232323", fontWeight: 600, fontSize: "24px" }}
            >
              Log out
            </span>

            <div className="py-5">
              <p
                style={{
                  color: "rgba(24, 40, 61, 1)",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                Are you sure you want to log out ?
              </p>
            </div>
            <div className="flex justify-center">
              <div className="flex justify-center gap-6 align-middle">
                <Button
                  btnName="Cancel"
                  btnStyle={{ color: "#000" }}
                  btnCategory="secondary"
                  onClick={() => setIsLogout(false)}
                />
                <Button
                  btnType="button"
                  btnCls="w-[110px]"
                  btnName={"Logout"}
                  btnCategory="primary"
                  onClick={handleLogout}
                />
              </div>
            </div>
          </div>
        </Backdrop>

        <nav className="px-4 py-3 border-gray-200 lg:px-6 dark:bg-gray-800">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center gap-44">
              <span
                className="self-center font-semibold whitespace-nowrap text-[28px] cursor-pointer text-primary-blue"
                onClick={() => navigate("/")}
              >
                CRRMS
              </span>

              <div
                className="items-center justify-between w-full lg:flex md-flex lg:w-auto lg:order-1"
                id="mobile-menu-2"
              >
                <ul className="flex items-center mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 primary-black">
                  {userType === "consumer" && (
                    <>
                      <li>
                        <span
                          className="block py-2 pr-4 ml-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                          onClick={() => navigate("/dashboard")}
                        >
                          Home
                        </span>
                      </li>
                      <li className="flex items-center gap-1">
                        <span
                          className="block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                          onClick={() => navigate("/category")}
                        >
                          Categories
                        </span>
                        <span onClick={handleClickMenu}>
                          <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={menuOpen}
                          open={menuOpen}
                          onClose={() => setMenuOpen(null)}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              navigate("/addnewcategory");
                            }}
                          >
                            Add New Category
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              navigate("/categoryvisibility");
                            }}
                          >
                            Category Visibility
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              navigate("/allcategory");
                            }}
                          >
                            All Category
                          </MenuItem>
                        </Menu>
                      </li>
                      <li className="flex items-center gap-1">
                        <span
                          className="block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                          onClick={() => navigate("/all-request")}
                        >
                          My Requests
                        </span>
                      </li>
                    </>
                  )}
                  {(userType === "other" || userType === "agent") && (
                    <>
                      <li>
                        <span
                          className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                          onClick={() => navigate("/")}
                        >
                          Dashboard
                        </span>
                      </li>
                      {userType !== "agent" && (
                        <li className="flex items-center gap-1">
                          <div
                            onClick={handleAgentMenu}
                            className="flex items-center"
                          >
                            <span
                              className="block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                              onClick={() =>
                                userType === "agent" && navigate("/all-agent")
                              }
                            >
                              Agent
                            </span>
                            <span>
                              <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                            </span>
                          </div>
                          <Menu
                            id="basic-menu"
                            anchorEl={agentMenu}
                            open={agentMenu}
                            onClose={() => setAgentMenu(null)}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            className="text-[#353F4F]"
                          >
                            <MenuItem
                              onClick={() => {
                                handleCloseAgentMenu();
                                navigate("/addnewagent");
                              }}
                            >
                              Add New Agent
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleCloseAgentMenu();
                                navigate("/all-agent");
                              }}
                            >
                              All Agent
                            </MenuItem>
                          </Menu>
                        </li>
                      )}
                      <li className="flex items-center gap-1">
                        <span
                          className="block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                          onClick={() =>
                            userType === "agent" && navigate("/partners")
                          }
                        >
                          Partner
                        </span>
                        {userType !== "agent" && (
                          <>
                            {" "}
                            <span onClick={handlePartnerMenu}>
                              <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                            </span>
                            <Menu
                              id="basic-menu"
                              anchorEl={partnerMenu}
                              open={partnerMenu}
                              onClose={() => setPartnerMenu(null)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              className="text-[#353F4F]"
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClosePartnerMenu();
                                  navigate("/addnewpartner");
                                }}
                              >
                                Add New Partner
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClosePartnerMenu();
                                  navigate("/partners");
                                }}
                              >
                                All Partner
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </li>
                      <li className="flex items-center gap-1">
                        <span
                          className="block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F]"
                          onClick={() =>
                            userType === "agent" && navigate("/category")
                          }
                        >
                          Categories
                        </span>
                        {userType !== "agent" && (
                          <>
                            <span onClick={handleClickMenu}>
                              <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                            </span>
                            <Menu
                              id="basic-menu"
                              anchorEl={menuOpen}
                              open={menuOpen}
                              onClose={() => setMenuOpen(null)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              className="text-[#353F4F]"
                            >
                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  navigate("/addnewcategory");
                                }}
                              >
                                Add New Category
                              </MenuItem>

                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  navigate("/categoryvisibility");
                                }}
                              >
                                Category Visibility
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleCloseMenu();
                                  navigate("/allcategory");
                                }}
                              >
                                All Category
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </li>
                      <li className="flex items-center">
                        <span
                          className="block py-2 pl-3 font-normal cursor-pointer primary-black"
                          onClick={() => navigate("/all-request")}
                        >
                          Requests
                        </span>
                        <span onClick={handleRequestMenu}>
                          <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={requestMenu}
                          open={requestMenu}
                          onClose={() => setRequestMenu(null)}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            onClick={() => {
                              handleCloseRequestMenu();
                              navigate("/addnewrequest");
                            }}
                          >
                            Create Request
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleCloseRequestMenu();
                              navigate("/Requests");
                            }}
                          >
                            All request
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleCloseRequestMenu();
                              navigate("/Requests");
                            }}
                          >
                            Request Tracking
                          </MenuItem>
                        </Menu>
                      </li>
                    </>
                  )}
                  {userType === "admin" && (
                    <>
                    
                      <li>
                        <span
                          className={`block py-2  pl-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "dashboard" ? "font-semibold" : ""
                          }`}
                          onClick={() => {
                            setActiveItem("dashboard"); // Set the active item to 'dashboard'
                            navigate("/");
                          }}
                        >
                          Dashboard
                          {activeItem === "dashboard" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                      </li>
                      <li
                        className="flex items-center gap-1"
                        onClick={handleAgentMenu}
                      >
                       <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "agent" ? "font-semibold" : ""
                          }`}>
                          Agent
                          {activeItem === "agent" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>

                        <span>
                          {agentMenu ? (
                            <KeyboardArrowUpIcon className="text-[#353F4F] cursor-pointer" />
                          ) : (
                            <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                          )}
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={agentMenu}
                          open={Boolean(agentMenu)}
                          onClose={handleCloseAgentMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseAgentMenu();
                              setActiveItem("agent");
                              navigate("/addnewagent");
                            }}
                          >
                            Add New Agent
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseAgentMenu();
                              setActiveItem("agent");
                              navigate("/all-agent");
                            }}
                          >
                            All Agent
                          </MenuItem>
                        </Menu>
                      </li>

                      <li
                        className="flex items-center gap-1"
                        onClick={handlePartnerMenu}
                      >
                          <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "partner" ? "font-semibold" : ""
                          }`}>
                          Partner
                          {activeItem === "partner" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        <span>
                          {partnerMenu ? (
                            <KeyboardArrowUpIcon className="text-[#353F4F] cursor-pointer" />
                          ) : (
                            <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                          )}
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={partnerMenu}
                          open={Boolean(partnerMenu)}
                          onClose={handleClosePartnerMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            sx={{
                              backgroundColor: "transparent",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleClosePartnerMenu();
                              setActiveItem("partner");
                              navigate("/addnewpartner");
                            }}
                          >
                            Add New Partner
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "transparent",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleClosePartnerMenu();
                              setActiveItem("partner");
                              navigate("/partners");
                            }}
                          >
                            All Partner
                          </MenuItem>
                        </Menu>
                      </li>
                      <li
                        className="flex items-center gap-1"
                        onClick={handleCategoryMenu}
                      >
                        <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "categories" ? "font-semibold" : ""
                          }`}>
                          Categories
                          {activeItem === "categories" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        <span>
                          {categoryMenu ? (
                            <KeyboardArrowUpIcon className="text-[#353F4F] cursor-pointer" />
                          ) : (
                            <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                          )}
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={categoryMenu}
                          open={Boolean(categoryMenu)}
                          onClose={handleCloseCategoryMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseCategoryMenu();
                              setActiveItem("categories");
                              navigate("/addnewcategory");
                            }}
                          >
                            Add New Category
                          </MenuItem>

                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseCategoryMenu();
                              setActiveItem("categories");
                              navigate("/categoryvisibility");
                            }}
                          >
                            Category Visibility
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseCategoryMenu();
                              setActiveItem("categories");
                              navigate("/allcategory");
                            }}
                          >
                            All Category
                          </MenuItem>
                        </Menu>
                      </li>
                      <li
                        className="flex items-center gap-1"
                        onClick={handleRequestMenu}
                      >
                         <span
                          className={`block py-2 ml-3 primary-black font-normal cursor-pointer text-[#353F4F] text-[17px] relative ${
                            activeItem === "requests" ? "font-semibold" : ""
                          }`}>
                          Requests
                          {activeItem === "requests" && (
                            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[28px] border-b-[3px] border-[#353F4F]" />
                          )}
                        </span>
                        <span>
                          {requestMenu ? (
                            <KeyboardArrowUpIcon className="text-[#353F4F] cursor-pointer" />
                          ) : (
                            <KeyboardArrowDownIcon className="text-[#353F4F] cursor-pointer" />
                          )}
                        </span>
                        <Menu
                          id="basic-menu"
                          anchorEl={requestMenu}
                          open={Boolean(requestMenu)}
                          onClose={handleCloseRequestMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: "none",
                              border: "1px solid #3b82f77f",
                            },
                          }}
                          className="text-[#353F4F]"
                        >
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/create-request");
                            }}
                          >
                            Create Request
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/all-request");
                            }}
                          >
                            All request
                          </MenuItem>
                          <MenuItem
                            sx={{
                              backgroundColor: "white",
                              fontSize: "14px",
                              "&:hover": {
                                backgroundColor: "#EEF4FF",
                              },
                            }}
                            onClick={() => {
                              handleCloseRequestMenu();
                              setActiveItem("requests");
                              navigate("/request-tracking");
                            }}
                          >
                            Request Tracking
                          </MenuItem>
                        </Menu>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {Object.keys(data).length === 0 && (
              <div className="flex items-center lg:order-2">
                <span
                  className="px-16 py-3 font-normal text-white rounded cursor-pointer bg-primary-blue"
                  onClick={() => setLoginModal(true)}
                >
                  Login/Signup
                </span>
              </div>
            )}

            {Object.keys(data).length > 0 && (
              <div className="flex gap-10 items-center ">
                <div className="border-r border-[#D9D9D9] pr-10 py-1">
                  <button
                    className="bg-primary-orange text-white px-7 py-3 rounded flex gap-3 cursor-pointer "
                    onClick={() => navigate("/create-request")}
                  >
                    <img src={PlusIcon} alt="PlusIcon" />
                    <span> New Request</span>
                  </button>
                </div>
                <img
                  src={NotificationIcon}
                  alt="NotificationIcon"
                  onClick={() => navigate("/notification")}
                  className="cursor-pointer"
                />
                <img
                  className="object-cover w-8 h-8 cursor-pointer rounded-3xl"
                  src={UserImage}
                  alt="User Icon"
                  onClick={handleClick}
                />

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/help");
                    }}
                  >
                    <img
                      src={HelpIcon}
                      alt="HelpIcon"
                      className="pr-3 w-[30px]"
                    />
                    Help
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setUserAction({ ...userAction, profileModal: true });
                    }}
                  >
                    <img
                      src={ProfileIcon}
                      alt="ProfileIcon"
                      className="pr-3 w-[30px]"
                    />
                    Profile
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setIsLogout(true);
                    }}
                  >
                    <img
                      src={LogoutIcon}
                      alt="LogoutIcon"
                      className="pr-3 w-[30px]"
                    />
                    Log out
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </nav>
      </header>

      <MuiModal
        modalSize="md"
        modalOpen={loginModal}
        modalClose={handleCloseLoginModal}
        noheader
      >
        <div className="flex flex-col items-center py-7">
          <h5 className="text-primary-blue text-[26px] text-center font-medium">
            CRRMS
          </h5>
          <p className="text-primary-black text-[18px] py-4">
            Welcome to the Request Management System
          </p>
          <p className="text-primary-black text-[16px] pb-6">
            Login for a seamless experience
          </p>
          <div className="flex gap-4">
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onLogoutFailure={onLogoutFailure}
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              scope="openid profile email"
              onResolve={(e) => {
                onLoginSuccess(e.data);
              }}
              onReject={(err) => {
                console.log("hbhbdhd", err);
              }}
            >
              <div
                className="px-6 py-3 cursor-pointer"
                style={{
                  border: "0.5px solid rgba(34, 96, 217, 0.5)",
                  borderRadius: "3px",
                }}
              >
                <img src={GoogleIcon} alt="GoogleIcon" />
              </div>
            </LoginSocialGoogle>
            <div
              className="px-6 py-3 cursor-pointer"
              style={{
                border: "0.5px solid rgba(34, 96, 217, 0.5)",
                borderRadius: "3px",
              }}
            >
              <img src={InstagramIcon} alt="InstagramIcon" />
            </div>
            <div
              className="px-6 py-3 cursor-pointer"
              style={{
                border: "0.5px solid rgba(34, 96, 217, 0.5)",
                borderRadius: "3px",
              }}
            >
              <img src={FacebookIcon} alt="FacebookIcon" />
            </div>
            <div
              className="px-6 py-3 cursor-pointer"
              style={{
                border: "0.5px solid rgba(34, 96, 217, 0.5)",
                borderRadius: "3px",
              }}
            >
              <img src={EmailIcon} alt="EmailIcon" />
            </div>
          </div>
          <div
            className="mb-8 mt-8 flex items-center before:mt-0.5 before:flex-1 before:border-t
                     before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t
                      after:border-neutral-300 dark:before:border-neutral-500
                       dark:after:border-neutral-500"
            style={{ color: "rgba(183, 185, 189, 1)", width: "50%" }}
          >
            <p
              className="mx-4 mb-0 font-semibold text-center dark:text-neutral-200"
              style={{
                color: "rgba(183, 185, 189, 1)",
                border: "1px solid rgba(183, 185, 189, 1)",
                padding: "2px 20px",
                borderRadius: "20px",
                fontSize: "14px",
              }}
            >
              OR
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="w-[50%]">
            <div className="flex flex-wrap gap-2">
              <div className="relative w-full mb-6">
                <input
                  type={"number"}
                  className={`w-full rounded px-3 py-[0.32rem] text-[14px] leading-[2.15] h-[60px] ${
                    errors["mobile_number"]
                      ? "focus:border-teal focus:outline-none focus:ring-0"
                      : ""
                  }`}
                  placeholder={"Enter Mobile Number"}
                  style={{
                    color: "#232323",
                    border: `1px solid ${
                      errors["mobile_number"]
                        ? "rgb(239 68 68)"
                        : "rgba(34, 96, 217, 1)"
                    }`,
                  }}
                  {...register("mobile_number", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please Enter Valid Mobile Number",
                    },
                  })}
                  aria-invalid={errors["mobile_number"] ? "true" : "false"}
                />
                {errors["mobile_number"] && (
                  <p className="error" role="alert">
                    {errors["mobile_number"].message}
                  </p>
                )}
              </div>

              <div className="relative w-full">
                <button
                  type="submit"
                  className="inline-block w-full py-4 text-sm font-medium text-white rounded px-7 bg-primary-blue "
                  data-twe-ripple-init
                  data-twe-ripple-color="light"
                >
                  Login with OTP
                </button>
              </div>
            </div>
          </form>
        </div>
      </MuiModal>

      <MyProfile
        open={userAction.profileModal}
        closeModal={handleCloseUserProfile}
      />
    </>
  );
}
