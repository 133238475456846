import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestAdmin } from "../../services/adminRequest";
import TopAgentList from "./TopAgentList";
import CurrentRequest from "./CurrentRequest";
import TopPartnerList from "./TopPartenerList";
import Chart from "./LineChart";
import { useNavigate } from "react-router";



const AdminDashBoard = () => {
  const dispatch = useDispatch();
const navigate = useNavigate();
  const requestData = useSelector((state) => state.admin.details);


  useEffect(() => {
    dispatch(getRequestAdmin());
  }, [dispatch]);

  
  return (
    <div className="bg-[#F9F9F9] p-6 flex flex-col gap-6">
      <div className="grid grid-cols-11 gap-6">
        <div className="col-span-3 custom-shadow rounded-[5px]">
          <div className="flex flex-col justify-center h-full gap-4 px-8">
            <p className="font-semiBold text-[#1D5BBF] text-[35px]">
              Good Morning, John
            </p>
            <div className="flex text-[#353F4F] text-[22px]">
              <p className="border-r border-[#353F4F] pr-5">09.29 AM</p>
              <p className="pl-5">09-08-2024</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 col-span-8 gap-6">
          {requestData?.requests && requestData?.requests.length > 0 ? (
            requestData?.requests.map((item, index) => (
              <div
                key={index}
                className={`flex flex-col px-4 border cursor-pointer rounded ${
                  index === 0
                    ? "border-[#1C65F3]"
                    : index === 1
                    ? "border-[#17900B]"
                    : index === 2
                    ? "border-[#FFD500]"
                    : "border-[#FF7B00]"
                } ${
                  index === 0
                    ? "bg-[#ECF2FF]"
                    : index === 1
                    ? "bg-[#F1FFEF]"
                    : index === 2
                    ? "bg-[#FEFFD9]"
                    : "bg-[#FFF5E7]"
                  }`}
                onClick={() => navigate(`/agentrequest?status=${item.request_via}`)}
              >
                <div className="flex flex-col rounded-[5px] gap-3">
                  <div className="flex items-center justify-between gap-[13px] border-b border-[#D9D9D9] font-medium pt-8 pb-4 ">
                    <p className="text-[#353F4F] text-lg capitalize">
                      {item.request_via} Request
                    </p>
                    <div className="w-[6rem]">
                      <p className="bg-[#596A82] text-white text-[38px] rounded text-center w-full py-1">
                        {item.request_count}
                      </p>
                    </div>
                  </div>
                  <div className="text-xs pb-1.5">
                    <p>
                      Metrics:{" "}
                      <span className="text-[#2260D9]">Current Month</span>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No request data available.</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-11 gap-6">
        <div className="col-span-3 custom-shadow p-6 rounded-[5px] bg-[#FFFFFF] ">
          <p className="font-medium text-[#353F4F] text-lg border-b border-[#D3D3D3] pb-6">
            Top Agents List
          </p>
          <TopAgentList />
        </div>
        <div className="col-span-3 custom-shadow p-6 rounded-[5px] bg-[#FFFFFF]">
          <p className="font-medium text-[#353F4F] text-lg border-b border-[#D3D3D3] pb-6">
            Top Partner List
          </p>
          <TopPartnerList />
        </div>
        <div className="col-span-5 custom-shadow p-6 rounded-[5px] bg-[#FFFFFF]">
          <p className="font-medium text-[#353F4F] text-lg  border-[#D3D3D3] pb-6 w-full">
            Request Statics info
          </p>
          <Chart />
        </div>
      </div>

      
       <CurrentRequest/>
       
    </div>
  );
};

export default AdminDashBoard;
