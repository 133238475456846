import {
  configureStore,
  combineReducers
} from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import categoryReducer from "../features/category/categorySlice";
import requestReducer from "../features/request/requestSlice";
import parnerReducer from "../features/partner/partnerSlice";
import adminReducer from "../features/adminRequest/adminRequestSlice";
import agentListReducer from "../features/agentList/agentListSlice";
import partnerListReducer from "../features/partnerList/partnerListSlice";
import curretRequestReducer from "../features/currentRequest/currentRequestSlice";
import requestGraphReducer from "../features/requestGraph/requestGraphSlice";
import agentReducer from '../features/agent/agentSlice';
import  userTypeReducer  from "../features/userType/userTypeSlice";
import loginReducer from "../features/login/loginSlice";
import notificationReducer from '../features/notification/notificationSlice'

const combinedReducer = combineReducers({
  userInfo: userReducer,
  loginInfo: loginReducer,
  categoryInfo: categoryReducer,
  requestInfo: requestReducer,
  partnerInfo: parnerReducer,
  admin: adminReducer,
  agentData: agentListReducer,
  partnerData: partnerListReducer,
  currentRequest: curretRequestReducer,
  graphInfo: requestGraphReducer,
  agentInfo: agentReducer,
  userTypeData: userTypeReducer,
  notificationInfo:notificationReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer

});