import { Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import Dashboard from "./components/Dashboard";
import CreateRequest from "./components/Request/CreateRequest";
import Category from "./components/Category";
import AllRequest from "./components/Request/AllRequest";
import Notification from "./components/Notification";
import Help from "./components/Help";
import ViewRequest from "./components/Request/View";
import ViewCategory from "./components/Category/ViewCategory";
import Partner from "./components/Partner";
import ViewPartner from "./components/Partner/ViewPartner";
import Profile from "./components/Profile";
import PartnerDashboard from "./components/PartnerDashboard";
import PartnerRequestsView from "./components/PartnerRequestsView";
import PartnerRequestDetailView from "./components/PartnerRequestDetailView";
import PartnerTrackingView from "./components/PartnerTrackingView";
import Logout from "./components/Logout";
import AllCategory from "./components/Category/AllCategory";
import AddNewCategory from "./components/Category/AddNewCategory";
import CategoryVisibility from "./components/Category/CategoryVisibility";
import LogIn from "./components/Login/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import ConfirmOtp from "./components/Login/ConfirmOtp";
import ChangePassword from "./components/Login/ChangePassword";
import AddNewPartner from "./components/Partner/AddNewPartner";

import AddNewAgent from "./components/Agent/AddNewAgent";
import AllAgent from "./components/Agent/AllAgent";
import ProtectedRoutes from "./components/ProtectedRoutes";
import AgentView from "./components/Category/AgentView";
import AddNewRequest from "./components/Request/AddNewRequest";
import ViewAgent from "./components/Category/ViewAgent";
import ExternalCategory from "./components/Category/ExternalCategory";
import EditCategory from "./components/Category/EditCategory";
import AgentProfile from "./components/Category/AgentProfile";
import RequestDetails from "./components/Request/AllRequest/RequestDetails";
import AgentRequestVia from "./components/Admin-DashBoard/AgentRequestVia";
// import AddCategoryPartners from "./components/Category/AddCategoryPartners";

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<ProtectedRoutes Component={Dashboard} />} />
        {/* <Route path="/" element={<Dashboard/>} /> */}
        <Route path="/notification" element={<Notification />} />
        <Route path="/help" element={<Help />} />
        <Route path="/category" element={<Category />} />
        <Route path="/allcategory" element={<AllCategory />} />
        <Route path="/addnewcategory" element={<AddNewCategory />} />
        <Route path="/categoryvisibility" element={<CategoryVisibility />} />
        <Route path="/externalcategory/:id" element={<ExternalCategory />} />
        <Route path="/editcategory/:id" element={<EditCategory />} />
        {/* <Route path="/addcategorypartner/:id" element= {<AddCategoryPartners/>} /> */}
        <Route path="/addnewagent" element={<AddNewAgent />} />
        <Route path="/all-agent" element={<AllAgent />} />
        <Route path="/addnewpartner" element={<AddNewPartner />} />
        <Route path="/agentview" element={<AgentView />} />
        <Route path="/view-agent/:id" element={<ViewAgent />} />
        <Route path="/agentprofile/:id" element={<AgentProfile />} />
        <Route path="/agentrequest" element={<AgentRequestVia />} />

        <Route path="/category/:id" element={<ViewCategory />} />
        <Route path="/create-request" element={<CreateRequest />} />
        <Route path="/all-request" element={<AllRequest />} />
        <Route path="/view-request/:id" element={<ViewRequest />} />
        <Route path="/request-tracking" element={<RequestDetails />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/Partners" element={<Partner />} />
        <Route path="/partner/:id" element={<ViewPartner />} />

        <Route path="/partner">
          <Route path="dashboard" element={<PartnerDashboard />} />
          <Route path="requests" element={<PartnerRequestsView />} />
          <Route
            path="requests/:requestId"
            element={<PartnerRequestDetailView />}
          />
          <Route
            path="requests/:requestId/tracking"
            element={<PartnerTrackingView />}
          />
        </Route>
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/confirmotp/:email" element={<ConfirmOtp />} />
      <Route path="/changepassword" element={<ChangePassword />} />
    </Routes>
  );
}

export default App;
