import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import DataTable from "../../shared/DataGrid";
import { CategoryTab } from "../../utills/formFields";
import BreadCrumIcon from "../../assets/breadcrumb-right-icon.svg";
import SearchIcon from "../../assets/input-search-icon.svg";
import MoreIcon from "../../assets/more-icon.svg";
import { CategoryColumn } from "../../utills/tableFields";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategoryList,
  updateCategoryStatus,
} from "../../services/category";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import EyeOpenIcon from "../../assets/eye-open-black.svg";
import EyeClosedIcon from "../../assets/eye-closed-black.svg";
import AddPartnersInCategories from "../Popups/AddPartnersInCategories";
import BreadCumbs from "../BreadCumbs/BreadCumbs";
import Crossicon from "../../assets/crossicon.svg";
import Deleteicon from "../../assets/deleteicon.svg";
import Checkimg from "../../assets/checkimg.svg";
import Crossimg from "../../assets/crossimg.svg";
import Checkicon from "../../assets/checkicon.svg";
import Archiveicon from "../../assets/archivedicon.svg";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RightIcon from "../../assets/check-icon.svg";
import BlockIcon from "../../assets/block-icon.svg";
import moment from "moment";

export default function AllCategory() {
  const navigate = useNavigate();
  const [actionTab, setActiveTab] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [activateDialogOpen, setActivateDialogOpen] = useState(false); // New state for Activate dialog
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState({
    action: "",
    id: "",
  });
  const [cancelButtonActive, setCancelButtonActive] = useState(false);
  const [deactivateButtonActive, setDeactivateButtonActive] = useState(false);
  const [activateButtonActive, setActivateButtonActive] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [comment, setComment] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleAction = (action, id) => {
    setConfirmAction({ action, id });
    if (action === "active") {
      setActivateDialogOpen(true);
    } else if (action === "archive") {
      handleDeleteDialogOpen();
    } else {
      setConfirmDialogOpen(true);
    }
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false); // Closes the dialog
  };

  const handleConfirmAction = () => {
    // Validate comment for "deactivated" status
    if (confirmAction.action === "inactive" && !comment.trim()) {
      setDialogMessage("Reason is required when status is 'deactivated'.");
      setSuccessDialogOpen(true);
      return;
    }

    let message = "";
    let imageSrc = "";

    switch (confirmAction.action) {
      case "inactive":
        message = `Category successfully deactivated.`;
        imageSrc = Crossicon;
        break;
      case "active":
        message = `Category successfully activated.`;
        imageSrc = Checkicon;
        break;
      case "archive":
        message = `Category successfully archived.`;
        imageSrc = Archiveicon;
        break;
      default:
        break;
    }

    dispatch(
      updateCategoryStatus({
        category_id: confirmAction.id,
        status: confirmAction.action,
      })
    );

    setTimeout(() => {
      dispatch(getAllCategoryList());
    }, 50);

    setConfirmAction({ action: "", id: "" });
    setDialogMessage(message);
    setDialogImage(imageSrc);
    setConfirmDialogOpen(false);
    setActivateDialogOpen(false);
    setSuccessDialogOpen(true);
    setDeleteDialogOpen(false);
    setComment(""); 
    handleClose();

    setTimeout(() => {
      setSuccessDialogOpen(false); 
      window.location.reload();
    }, 2000);

  };

  const handleDialogClose = () => setSuccessDialogOpen(false);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleActivateDialogClose = () => setActivateDialogOpen(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = (row) => {
    navigate(`/editcategory/${row.id}`, { state: { rowData: selectedRow } });
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  const categoryData = useSelector((state) => state.categoryInfo.data.data);

  const CategorysColumn = [
    ...CategoryColumn,
    {
      field: "date",
      headerName: "Last Update Date & Time",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return moment(params?.row?.updated_at).format("MM/DD/YYYY | h:mm A");
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const statusStyles = {
          active: "text-[#29B473]" ,
          inactive: "text-[#D22735]",
        };
        const status = params.row.status;
        return (
          <div
            className={`flex items-center gap-2 ${statusStyles[params.value]}`}
          >
            {status === "active" ? (
              <img src={RightIcon} />
            ) : (
              <img src={BlockIcon} />
            )}
            <span className="capitalize">
              {status}
            </span>
          </div>
        );
      },
    },     
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      id: 4,
      renderCell: (params) => {
        const status = params.row.status;

        const handleDeleteDialogClose = () => {
          setDeleteDialogOpen(false);
        };

        return (
          <>
            <Menu as="div" className="inline-block text-left mt-2">
              <div>
                <MenuButton className="relative inline-flex w-full justify-center gap-x-1.5   px-3 py-2 text-sm font-semibold text-gray-900">
                  <img
                    src={MoreIcon}
                    alt="MoreIcon"
                    onClick={(e) => handleClick(e, params.row)}
                  />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute right-10 z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem
                    onClick={() => {
                      navigate(`/externalcategory/${params.id}`);
                    }}
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      View
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleEdit(params.row);
                    }}
                  >
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                    >
                      Edit
                    </a>
                  </MenuItem>
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => handleAction("inactive", params.id)}
                    >
                      <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                        Deactivate
                      </p>
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem
                        onClick={() => handleAction("active", params.id)}
                        // className="!text-[12px] text-green-600 hover:bg-[#D1FAE5] hover:text-green-800"
                      >
                        <p className=" cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]">
                          Activate
                        </p>
                      </MenuItem>
                    </>
                  )}
                  {status === "active" ? (
                    <MenuItem
                      onClick={() => {
                        setOpen(params.id);
                      }}
                    >
                      <button
                        type="submit"
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Add Partners
                      </button>
                    </MenuItem>
                  ) : status === "archive" ? (
                    <MenuItem
                      onClick={() => handleAction("unarchive", params.id)}
                    >
                      <button
                        type="unarchive"
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Unarchive
                      </button>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => handleAction("archive", params.id)}
                    >
                      <button
                        type="archive"
                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none hover:bg-[#EEF4FF]"
                      >
                        Add To Archived
                      </button>
                    </MenuItem>
                  )}
                </div>
              </MenuItems>
            </Menu>
          </>
        );
      },
    },
  ];

  const tabCount = useMemo(() => {
    const obj = {
      all: 0,
      active: 0,
      inactive: 0,
    };
    obj.all = categoryData?.length;
    obj.active = categoryData?.filter((c) => c?.status === "active")?.length;
    obj.inactive = categoryData?.filter(
      (c) => c?.status === "inactive"
    )?.length;
    return obj;
  }, [categoryData]);

  const activeTabData = useMemo(() => {
    if (actionTab === "all") {
      if (isOpen) {
        return categoryData?.filter((c) => c?.status === "archive");
      }
    } else if (actionTab === "active") {
      return categoryData?.filter((c) => c?.status === "active");
    } else if (actionTab === "inactive") {
      // return categoryData?.filter((c) => c?.status === "inactive");
      if (isOpen) {
        return categoryData?.filter((c) => c?.status === "archive");
      }
      return categoryData?.filter((c) => c?.status === "inactive");
    }

    return categoryData;
  }, [actionTab, categoryData, isOpen]);

  const rows =
    activeTabData && activeTabData.length > 0
      ? activeTabData.map((item) => ({
          id: item.id,
          categoryId: item.id,
          category: item.name,
          category_type: item.category_type,
          available_partner: item.partner_count,
          managed_request: item.request_count,
          date: item.updated_at,
          status: item.status,
        }))
      : [];

  return (
    <>
      <div className="px-8 pt-10 bg-[#F9F9F9]">
        <BreadCumbs
          firstCumbName="Category"
          secoundCumbName="Category Status"
          pathToRedirect="addnewcategory"
        />

        <div className="px-3 py-10  my-10 custom-shadow rounded-[5px]">
          <div className="text-[#18283D] text-[18px] font-sans border-b border-[#E4E6EF] pl-6 pb-4">
            Category
          </div>
          <div className="px-5">
            <div className="details-info pt-8">
              <div
                className="flex justify-between  mb-8 "
                style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}
              >
                <ul className="tab-list">
                  {CategoryTab.map((discussion, index) => (
                    <li
                      className={`${
                        actionTab === discussion.key ? "active" : ""
                      } relative`}
                      key={index}
                      onClick={() => setActiveTab(discussion.key)}
                    >
                      <div className="flex justify-center pb-1">
                        <div
                          className={`total-proram-count relative ${
                            actionTab === discussion.key ? "active" : ""
                          }`}
                        >
                          {tabCount[discussion?.key]}
                        </div>
                      </div>
                      <div className="text-[13px]">{`${discussion.name}`}</div>
                      {actionTab === discussion.key && <span></span>}
                    </li>
                  ))}
                </ul>
                <div className="flex items-center gap-5 pb-2">
                  {actionTab !== "active" && (
                    <div className="flex items-center gap-2">
                      <img
                        src={!isOpen ? EyeOpenIcon : EyeClosedIcon}
                        className="text-[#353F4F]"
                        onClick={handleToggle}
                      />
                      <p>Archived Categories</p>
                    </div>
                  )}

                  <div className="relative">
                    <input
                      type="text"
                      id="search-navbar"
                      className="block w-full p-2 text-sm text-gray-900 border-none rounded-[3px]"
                      placeholder="Search here..."
                      style={{
                        border: "1px solid rgba(34, 96, 217, 1)",
                        height: "50px",
                        width: "390px",
                      }}
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                    {/* <div className="text-[13px]">{`${discussion.name}`}</div> */}
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              rows={rows}
              columns={CategorysColumn}
              hideCheckbox
              hideFooter
            />
          </div>
        </div>
        <AddPartnersInCategories open={openPopup} setOpen={setOpen} />
      </div>

      <Dialog
        open={successDialogOpen}
        onClose={handleDialogClose} // Close on outside click
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{ width: "450px", height: "180px", borderRadius: "10px" }}
        >
          <img
            src={dialogImage}
            alt="Success"
            style={{ display: "block", margin: "0 auto" }}
          />
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {dialogMessage}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl"
      >
        <div className="bg-white  shadow-lg w-[600px] sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Checkimg} alt="Check" className="mx-2" />
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Crossicon} alt="Cross" className="mx-2" />
          </div>

          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="mb-4">
            <label
              htmlFor="Comments"
              className="text-[#353F4F] font-medium flex items-center"
            >
              Comments <span className="text-red-600 ml-1">*</span>
            </label>
            <textarea
              id="Comment"
              name="Comment"
              rows={6}
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-[#D6E5EE] placeholder:text-gray-400 focus:outline-2 focus:outline-indigo-600 sm:text-sm"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your reason for changing the status"
              required
            />
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={handleConfirmDialogClose}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#DC3545" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmAction}
              className="text-[#353F4F] px-6 py-3 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#DC3545",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setDeactivateButtonActive(true)}
              onMouseUp={() => setDeactivateButtonActive(false)}
            >
              Deactivate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={activateDialogOpen}
        onClose={handleActivateDialogClose}
        className="flex items-center justify-center max-w-none rounded-xl overflow-hidden"
      >
        <div className="bg-white shadow-lg w-[600px] overflow-hidden sm:max-w-sm md:max-w-md lg:max-w-xl xl:max-w-2xl px-4 sm:px-8 md:px-16 lg:px-20 py-6 sm:py-8 md:py-10 relative">
          <div className="flex items-center justify-center mb-4">
            <img src={Crossimg} alt="Cross" className=" mx-2" />

            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              --
              <ArrowForwardIcon />
              --
            </Typography>
            <img src={Checkicon} alt="Check" className=" mx-2" />
          </div>
          <DialogContent className="mb-4 text-center">
            <Typography className="text-[#353F4F] text-base font-medium">
              Are you sure you want to change the status?
            </Typography>
          </DialogContent>
          <div className="flex justify-center">
            <button
              onClick={handleActivateDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#DC3545] hover:text-white hover:border-none"
              style={{
                backgroundColor: cancelButtonActive ? "#278E1D" : "white",
                color: cancelButtonActive ? "white" : "#353F4F",
                border: cancelButtonActive ? "none" : "1px solid #18283D80",
              }}
              onMouseDown={() => setCancelButtonActive(true)}
              onMouseUp={() => setCancelButtonActive(false)}
            >
              Cancel
            </button>

            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#28A745] hover:text-white hover:border-none"
              style={{
                backgroundColor: "#278E1D",
                color: "white",
                border: "none",
              }}
              onMouseDown={() => setActivateButtonActive(true)}
              onMouseUp={() => setActivateButtonActive(false)}
            >
              Activate
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        className="flex items-center justify-center"
      >
        <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
          <div className="flex items-center justify-center mb-4">
            <Typography
              variant="h6"
              className="text-xl text-center font-semibold text-[#353F4F]"
            >
              <img src={Archiveicon} alt="" />
            </Typography>
          </div>

          <DialogContent className="mb-4">
            <Typography className="text-[#353F4F] text-base text-center">
              Are you sure you want to move this Category to Archive?
            </Typography>
          </DialogContent>

          <div className="flex justify-center">
            <button
              onClick={handleDeleteDialogClose}
              className="text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              No
            </button>
            <button
              onClick={handleConfirmAction}
              className="ml-2 text-[#353F4F] px-4 py-2 rounded-md transition-all hover:bg-[#FF6711] hover:text-white"
              style={{
                border: "1px solid #18283D80",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
