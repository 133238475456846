import React, { useState, useEffect } from "react";
import { Backdrop } from "@mui/material";

import { Button } from "../../shared/Button";

import SearchIcon from "../../assets/input-search-icon.svg";
import CalenderIcon from "../../assets/calendar-icon.svg";
import housingIcon from "../../assets/housing-repair-icon.svg";
import childcareIcon from "../../assets/childcare-icon.svg";
import counselingIcon from "../../assets/counseling-icon.svg";
import CloseRedBgIcon from "../../assets/close-red-icon.svg";
import { getUserType } from "../../services/userType";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  ignoreNotification,
  deleteNotification,
} from "../../services/notification";
import moment from "moment";

export default function Notification() {
  const [notificationAction, setNotificationIcon] = useState({
    modal: false,
    selectedItem: {},
  });

  const [notifications, setNotifications] = useState([]);
  const [todayNotifications, setTodayNotifications] = useState([]);
  const [yesterdayNotifications, setYesterdayNotifications] = useState([]);
  const [clearedIds, setClearedIds] = useState([]);
  const dispatch = useDispatch();

  const { data, status, userType } = useSelector((state) => state.userInfo);

  const notification = useSelector((state) => state.notificationInfo.data);
  console.log("notif", notification);
  const formatTime = (time) => {
    const duration = moment.duration(moment().diff(moment(time)));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());

    if (hours > 0) {
      return `${hours} hr${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? "s" : ""} ago`;
    }
    return "Just now";
  };

  useEffect(() => {
    dispatch(deleteNotification());
  }, [dispatch]);

  console.log("delete", deleteNotification);

  useEffect(() => {
    dispatch(getNotification())
      .then((response) => {
        if (response.payload && response.payload.data) {
          setNotifications(response.payload.data);
          categorizeNotifications(response.payload.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  const handleClearBtn = (item) => {
    setNotificationIcon({ modal: true, selectedItem: item });
  };

  const handleClosePopup = () => {
    setNotificationIcon({ modal: false, selectedItem: {} });
  };

  // const handleConfirmPopup = () => {
  //   handleClosePopup();
  // };

  useEffect(() => {
    dispatch(getUserType());
  }, [dispatch]);

  const getIcon = (status) => {
    switch (status) {
      case "in progress":
        return housingIcon;
      case "completed":
        return childcareIcon;
      case "pending":
        return counselingIcon;
      default:
        return childcareIcon; // Default icon if no match is found
    }
  };

  const categorizeNotifications = (notifications) => {
    const today = new Date().setHours(0, 0, 0, 0); // Start of today
    const yesterday = new Date(today - 24 * 60 * 60 * 1000); // Start of yesterday

    const isSameDay = (date1, date2) =>
      new Date(date1).setHours(0, 0, 0, 0) === date2;

    const todayData = notifications.filter((notification) =>
      isSameDay(notification.created_at || notification.updated_at, today)
    );

    const yesterdayData = notifications.filter((notification) =>
      isSameDay(notification.created_at || notification.updated_at, yesterday)
    );

    setTodayNotifications(todayData);
    setYesterdayNotifications(yesterdayData);
  };

  const handleIgnoreNotification = (notification) => {
    const {
      request: request_id,
      request_status_id: request_status_id,
      read,
    } = notification;

    if (!request_id || !request_status_id) {
      console.error("Both 'request_id' and 'request_status_id' are required.");
      return;
    }

    dispatch(ignoreNotification({ request_id, request_status_id }))
      .unwrap()
      .then((response) => {
        console.log("Notification ignored successfully:", response);
        setNotifications((prevNotifications) =>
          prevNotifications.map((item) =>
            item.id === request_id ? { ...item, read: true } : item
          )
        );
      })
      .catch((error) => {
        console.error("Failed to ignore notification:", error);
      });
  };

  const handleConfirmPopup = () => {

    dispatch(deleteNotification({ userType }))
      .unwrap()
      .then((response) => {
        setClearedIds([]);
        setNotifications([]);
        setTodayNotifications([]);
        setYesterdayNotifications([]);
      })
      .catch((error) => {
        console.error("Error clearing notifications:", error);
      });
    handleClosePopup();
  };

  return (
    <div className=" px-8 mt-10">
      <div
        className="px-3 py-5 mb-5"
        style={{
          boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
        }}
      >
        <div className="flex justify-between px-5 pb-4 mb-8 items-center">
          <div className="flex gap-5 items-center ">
            <p className="text-[24px]">All Notification</p>
          </div>
          <div className="flex gap-8 items-center">
            <div className="relative">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-sm text-gray-900 border-none focus:outline-none"
                placeholder="Search here..."
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  height: "50px",
                  width: "300px",
                }}
              />
              <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
            {userType === "consumer" && (
              <div
                className="relative flex gap-3 py-3 px-3"
                style={{
                  border: "1px solid rgba(34, 96, 217, 0.25)",
                  borderRadius: "3px",
                }}
              >
                <img src={CalenderIcon} alt="CalenderIcon" />
                <select
                  className="focus:outline-none"
                  onChange={() => undefined}
                >
                  <option value={"month"}>Month</option>
                  <option value={"year"}>Year</option>
                </select>
              </div>
            )}
          </div>
        </div>

        <div className="notification-list px-5">
          <div className="flex w-full justify-between items-center">
            <div className="flex gap-6 items-center">
              <p>Today</p>
              <p className="bg-secondary-blue text-primary-blue py-2 px-2">
                {todayNotifications.length}
              </p>
            </div>
            <div onClick={() => handleClearBtn()} className="cursor-pointer">
              <p className="underline text-primary-blue underline-offset-1 link-underline-primary-blue">
                Clear All
              </p>
            </div>
          </div>

          <div className="flex flex-col  gap-10 py-7">
            {todayNotifications.map((item, index) => {
              return (
                <div className="flex gap-10 w-full self-stretch" key={index}>
                  <div
                    style={{
                      boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.1)",
                      borderRadius: "3px",
                    }}
                    className="flex py-7 w-full px-8 items-center"
                  >
                    <div className="flex gap-4 w-[75%]">
                      {/* <img src={item.img} alt="EventPlanningIcon" /> */}
                      <img src={getIcon(item.status)} alt="Notification Icon" />
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <h5 className=" text-[14px]">{`Notification #${item.id}`}</h5>
                          {!item.read && (
                            <div className="w-1.5 h-1.5 bg-primary-blue rounded-full"></div>
                          )}
                          <p className="text-[#3E3E3E] text-[14px]">
                            {formatTime(item.updated_at)}
                          </p>
                        </div>
                        <p className="text-primary-black text-[14px] pt-3">
                          {item.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-end w-[25%] gap-4">
                      <button
                        className="text-primary-blue py-2 cursor-pointer w-[30%]"
                        style={{ borderRadius: "3px" }}
                        onClick={() => handleIgnoreNotification(item)}
                      >
                        Ignore
                      </button>

                      <button
                        className="bg-primary-blue text-white py-2 cursor-pointer w-[30%]"
                        style={{ borderRadius: "3px" }}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {yesterdayNotifications.length > 0 && (
            <>
              <div className="flex gap-6 items-center">
                <p>Yesterday</p>
                <p className="bg-secondary-blue text-primary-blue py-1 px-1">
                  {yesterdayNotifications.length}
                </p>
              </div>

              <div className="flex flex-col gap-10 py-7">
                {yesterdayNotifications.map((item, index) => (
                  <div className="flex gap-10 w-full self-stretch" key={index}>
                    <div
                      style={{
                        boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.1)",
                        borderRadius: "3px",
                      }}
                      className="flex w-full py-7 px-8 items-center"
                    >
                      <div className="flex gap-4 w-[75%]">
                        <img
                          src={getIcon(item.status)}
                          alt="Notification Icon"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2">
                            <h5 className=" text-[14px]">{`Notification #${item.id}`}</h5>
                            {!item.read && (
                              <div className="w-1.5 h-1.5 bg-primary-blue rounded-full"></div>
                            )}
                            <p className="text-[#3E3E3E] text-[14px]">
                              {formatTime(item.updated_at)}
                            </p>
                          </div>
                          <p className="text-primary-black text-[14px] pt-3">
                            {item.description}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-end w-[25%] gap-4">
                        <button
                          className="text-primary-blue py-2 cursor-pointer w-[30%]"
                          style={{ borderRadius: "3px" }}
                          onClick={() => handleIgnoreNotification(item)}
                        >
                          Ignore
                        </button>
                        <button
                          className="bg-primary-blue text-white py-2 cursor-pointer w-[30%]"
                          style={{ borderRadius: "3px" }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => 1 }}
            open={notificationAction.modal}
          >
            <div className="popup-content relative w-2/6 bg-white flex flex-col gap-2 h-[330px] justify-center items-center">
              <img
                src={CloseRedBgIcon}
                className="absolute top-1 right-1"
                alt="TickColorIcon"
                onClick={handleClosePopup}
              />

              <div className="py-5">
                <p
                  style={{
                    color: "rgba(24, 40, 61, 1)",
                    fontWeight: 600,
                    fontSize: "18px",
                  }}
                >
                  Are you sure want to Delete the notification??
                </p>
              </div>
              <div className="flex justify-center">
                <div className="flex gap-6 justify-center align-middle">
                  <Button
                    btnCls="w-[110px] text-primary-black"
                    btnName={"No"}
                    btnCategory="secondary"
                    onClick={handleClosePopup}
                  />
                  <Button
                    btnType="button"
                    btnCls="w-[110px]"
                    btnName={"Yes"}
                    style={{ background: "#E0382D" }}
                    btnCategory="primary"
                    onClick={handleConfirmPopup}
                  />
                </div>
              </div>
            </div>
          </Backdrop>
        </div>
      </div>
    </div>
  );
}
