import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SearchIcon from "../../../assets/input-search-icon.svg";
import ArrowDownIcon from "../../../assets/arrow-down-icon.svg";
import DonationIcon from "../../../assets/donation-icon.svg";
import CancelRequest from "./CancelRequest";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import StepLabel from "@mui/material/StepLabel";
import CheckRequest from "../../../assets/checkrequest.svg";
import RejectedRequest from "../../../assets/rejectedrequest.svg";
import HoldRequest from "../../../assets/holdrequest.svg";
import BreadCumbs from "../../BreadCumbs/BreadCumbs";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRequest } from "../../../services/currentRequest";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2260D9",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2260D9",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#C8DBFF",
    borderTopWidth: 8,
    borderRadius: 5,
    ...theme.applyStyles("dark", {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const RequestCard = ({
  icon,
  title,
  requestId,
  status,
  description,
  onViewRequest,
  onCardClick,
}) => {
  const navigate = useNavigate()
  return (
    <div onClick={onCardClick} className="flex justify-between gap-5 px-5 pt-5">
      <div
        className="flex items-center gap-4 pr-10"
        style={{ borderRight: "1px solid rgba(200, 219, 255, 1)" }}
      >
        <div
          className="px-4 py-4 bg-white"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <img src={icon} alt="Request Icon" />
        </div>
        <div className="flex flex-col gap-1">
          <h3 className="text-primary-black text-[16px]">{title}</h3>
          <div className={`text-${status === "In Progress" ? "yellow" : "gray"}`}>
            {status}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <p className="text-[14px]">Request ID : {requestId}</p>
        <div className="flex items-start">
          <p className="text-[12px]">{description}</p>
          <img
            src={ArrowDownIcon}
            className="pt-1.5 cursor-pointer"
            alt="ArrowDownIcon"
          />
        </div>
      </div>

      <div className="flex items-center gap-4">
        <button
          className="bg-primary-blue text-white py-3 px-5 w-[200px]"
          onClick={() => navigate(`/view-request/${requestId}`)}
        >
          View Request Detail
        </button>
      </div>
    </div>
  )
};

export default function RequestDetails({ handleView }) {

  const navigate = useNavigate();
  const [actionTab, setActiveTab] = useState("in progress");
  const [detailAction, setDetailAction] = useState({ cancelModal: false });
  const [selectedRequest, setSelectedRequest] = useState(null);
  const dispatch = useDispatch();
  
  const currentData = useSelector((state) => state.currentRequest.currentData);

  useEffect(() => {
    dispatch(getCurrentRequest({ status: actionTab }))
  }, [dispatch, actionTab])

  useEffect(() => {
    if (currentData?.[0]) handleCardClick(currentData?.[0])
  }, [currentData,])

  const handleChange = (tab) => {
    setActiveTab(tab);
    setSelectedRequest('')
  };

  const closeModal = () => {
    setDetailAction({ cancelModal: false });
  };



  const allSteps = [
    {
      label: "Request Received",
      description: "Request received from client",
      icon: CheckRequest,
      partner_status: "new",
    },
    {
      label: "Request On Hold",
      description: "Request is been put on Hold by partner",
      icon: HoldRequest,
      partner_status: "on hold",
    },
    {
      label: "Request Rejected",
      description: "Your request has been Rejected",
      icon: RejectedRequest,
      partner_status: "rejected",
    },
    {
      label: "Request Accepted",
      description: "Request has been accepted by the partner",
      icon: CheckRequest,
      partner_status: "accepted",
    },
    {
      label: "Request Completed",
      description: "Request has been successfully completed",
      icon: CheckRequest,
      partner_status: "completed",

    },
  ];

  const actionTabFilter = [
    { key: "new", name: "New" },
    { key: "in progress", name: "In-Progress" },
    { key: "completed", name: "Completed" },
    { key: "on hold", name: "On hold" },
    { key: "rejected", name: "Rejected" },
  ];

  const [activeStep, setActiveStep] = useState(1);

  const filteredSteps =
    {
      new: [allSteps[0], allSteps[3], allSteps[4]],
      "in progress": [allSteps[0], allSteps[3], allSteps[4]],
      completed: [allSteps[0], allSteps[3], allSteps[4]],
      "on hold": [allSteps[0], allSteps[1], allSteps[3], allSteps[4]],
      rejected: [allSteps[0], allSteps[2], allSteps[4]],
    }[actionTab] || [];

  const handleCardClick = (request) => {
    setActiveStep(filteredSteps?.findIndex((el) => el.partner_status === request.partner_status))
    setSelectedRequest(request);
  };

  return (
    <>
      <div className="px-8 mt-10">
        <BreadCumbs
          firstCumbName="Request"
          secoundCumbName="Request Status"
          thirdCumbName="Track Request"
          pathToRedirect=""
          pathToRedirectSecond="all-request"
        />
        <div
          className="px-6 py-2 details-info"
          style={{
            border: "1px solid rgba(228, 237, 255, 1)",
          }}
        >
          <div className="flex items-center justify-between px-5 mt-8 mb-8">
            <ul className="tab-list">
              {actionTabFilter.map((discussion, index) => (
                <li
                  className={`${actionTab === discussion.key ? "active" : ""
                    } relative`}
                  key={index}
                  onClick={() => handleChange(discussion.key)}
                >
                  <div className="text-[13px]">{discussion.name}</div>
                  {actionTab === discussion.key && <span></span>}
                </li>
              ))}
            </ul>
            <div className="relative mb-2">
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 text-sm text-gray-900 border-none"
                placeholder="Search here..."
                style={{
                  border: "1px solid rgba(34, 96, 217, 1)",
                  height: "50px",
                  width: "300px",
                }}
              />
              <div className="absolute inset-y-0 flex items-center pointer-events-none end-0 pe-3">
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>

          <div style={{ borderBottom: "1px solid rgba(211, 211, 211, 1)" }}>
            {selectedRequest ? (
              <div>
                <div className="flex justify-between px-5 pt-5">
                  <div className="flex items-center justify-center gap-10">
                    <div
                      className="px-4 py-2 bg-white"
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.15)",
                        borderRadius: "5px",
                        boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)",
                      }}
                    >
                      <img src={DonationIcon} alt="CallIcon" />
                    </div>
                    <h3 className="text-primary-black text-[24px]">
                      {selectedRequest.first_name}
                    </h3>
                    <div
                      style={{
                        border: "1px solid rgba(255, 205, 17, 1)",
                        borderRadius: "15px",
                      }}
                      className="px-8 py-1 bg-light-yellow text-yellow"
                    >
                      {selectedRequest.request_status}
                    </div>
                  </div>

                  <div className="flex items-center gap-4">
                    <button
                      style={{ border: "1px solid rgba(220, 53, 69, 1)" }}
                      className="py-3 px-5 w-[200px] text-primary-red"
                      onClick={() =>
                        setDetailAction({ ...detailAction, cancelModal: true })
                      }
                    >
                      Cancel Request
                    </button>
                    <button
                      className="bg-primary-blue text-white py-3 px-5 w-[200px]"
                      onClick={() => navigate(`/view-request/${selectedRequest?.id}`)}
                    >
                      View Request Detail
                    </button>
                  </div>
                </div>
                <div className="px-40">
                  <div className="request-info flex gap-4 text-[12px]">
                    <p>
                      Request ID :
                      <span className="underline text-primary-blue">
                        {selectedRequest.id}
                      </span>
                    </p>
                    <p>
                      Request Date :
                      <span className="underline text-primary-blue">
                        {selectedRequest.created_at}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p>Please select a request to view details.</p>
            )}
            <Box
              sx={{
                width: "100%",
                padding: "20px",
                marginY: "20px",
              }}
            >
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
                sx={{
                  "& .MuiStepConnector-line": {},
                  "& .MuiStepLabel-label": {
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#555",
                  },
                  "& .MuiStepConnector-line.Mui-active": {},
                  "& .MuiStepIcon-root.Mui-completed": {
                    color: "#28a745",
                  },
                }}
              >
                {filteredSteps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel
                      icon={
                        activeStep === index || index < activeStep ? (
                          <img
                            src={step.icon}
                            alt={step.label}
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        ) : (
                          <div className="border-[8px] border-[#C8DBFF] w-[40px] h-[40px] rounded-full"></div>
                        )
                      }
                    >
                      <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                        {step.label}
                      </Typography>
                      {(activeStep === index || index < activeStep) && (
                        <Typography sx={{ fontSize: "12px", color: "#777" }}>
                          {step.description}
                        </Typography>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            {filteredSteps[activeStep]?.label === "Request Rejected" ? (
              <div className="border border-[#E50027] m-4 bg-[#FFF9F9] rounded-[5px]">
                <h2 className="border-b border-[#E50027] text-[#E50027] text-[20px] font-medium p-4">
                  Rejected Reason
                </h2>
                <div className="flex flex-col gap-4 px-4 py-2">
                  <p className="font-normal text-[14px] text-[#848484] flex gap-2 items-center">
                    Commented on{" "}
                    <div className="w-1.5 h-1.5 bg-[#848484] rounded-full"></div>{" "}
                    12/04/2024
                  </p>
                  <p className="text-[#353F4F] text-[12px] font-normal">
                    {selectedRequest?.reason}
                  </p>
                </div>
              </div>
            ) : (
              <p className="text-[#353F4F] text-[16px] font-medium px-4 py-8">
                Note: {selectedRequest?.request_status_desc}
              </p>
            )}
          </div>

          <div className="flex flex-col pt-4 cursor-pointer gap-7 pb-9">
            {currentData?.map((request, index) => (
              <RequestCard
                key={index}
                icon={DonationIcon}
                title={request.first_name}
                requestId={request.id}
                status={request.status}
                description={request.description}
                onViewRequest={request.onViewRequest}
                onCardClick={() => handleCardClick(request)}
              />
            ))}
          </div>
        </div>
        <CancelRequest
          open={detailAction.cancelModal}
          closeModal={closeModal}
        />
      </div>
    </>
  );
}
